<template>
	<div class="home">
		<table-billing-invoice></table-billing-invoice>
	</div>
</template>

<script>
import tableBillingInvoice from '@/components/pages/maint/billinginvoice/tableBillingInvoice.vue';

export default {
	components: {
		'table-billing-invoice': tableBillingInvoice,
	}
};
</script>

<style>

</style>
